[data-menu-measure="child"] {
    white-space: nowrap;
}

.header-section {
    padding-top: $spacer*2;
    padding-bottom: $spacer*2;
    color: $secondary;
    background: $white;
    position: relative;
    z-index: 5;

    .container-xl {
        max-width: 1650px !important;
    }

    .container,
    .container-xl,
    .container-fluid {
        position: relative;
        display: flex;
    }

    &.fixed {
        position: fixed;
        z-index: 100;
        right: 0;
        left: 0;
        top: 0;
    }

    .mobile-menu,
    .btn-mobile-menu {
        &.opened ~ * {
            display: none !important;
        }
    }

    .mobile-menu {
        z-index: 998;
    }

    .logo-container {
        .logo-mobile {
            display: none;
        }

        .logo-normal {
            svg {
                width: 360px;
                height: auto;
            }
        }
    }

    &.mobile {
        height: 70px;

        .logo-container {
            position: absolute;
            top: -$spacer;
            left: 50%;
            transform: translateX(-50%);
        }

        .logo-mobile {
            display: block;
            width: 150px;
        }

        .logo-normal {
            display: none;
        }
    }

    .menu-container {
        @extend .text-right;
        @extend .ml-auto;
        margin-top: $spacer*2;
    }

    &.mobile .menu-container {
        align-items: center;
        display: flex;

        .menu-child {
            display: none;
        }
    }

    .socialmedia {
        @extend .list-inline;
        margin-left: 2rem;
        font-size: 1.125rem;

        a {
            .icon {
                transition: 200ms all ease-in;
                display: inline-block;
                transform-origin: center;
                transform: scale(1);
            }

            &:hover {
                .icon {
                    transform: scale(1.1);
                    color: $primary;
                }
            }
        }
    }

    .menu-container {
        .header-menu {
            font-size: 25px;
            font-weight: 700;
            text-transform: uppercase;

            ul.nav {
                @extend .list-inline;
                text-align: left !important;
                margin-bottom: 0;

                > li {
                    &:not(:last-child) {
                        margin-right: 2.5rem;
                    }

                    > .submenu {
                        margin: -0.25rem -0.5rem;
                        padding: 0.25rem 0.5rem;
                        display: inline-block;
                    }

                    > a,
                    > .submenu > a {
                        @extend .text-decoration-none;
                        display: inline-block;
                        position: relative;

                        &::before {
                            border-bottom: 2px solid $primary;
                            transition: 200ms all ease-in;
                            bottom: -3px; left: 0;
                            position: absolute;
                            content: '';
                            width: 0;
                        }

                        &:hover {
                            &::before {
                                width: 100%;
                            }
                        }
                    }

                    &.active {
                        a {
                            &::before {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .submenu {
                position: relative;

                ul.layer-2 {
                    box-shadow: 0 0.25rem 0.25rem rgba(0,0,0,0.2);
                    background: lighten($primary,5%);
                    pointer-events: none;
                    position: absolute;
                    min-width: 10rem;
                    margin: 0 -0.5rem;
                    z-index: -1;
                    opacity: 0;
                    padding: 0;
                    top: 100%;

                    li {
                        padding: 0.25rem 0.5rem;
                        list-style: none;
                        display: block;
                        margin: 0;

                        &:first-child {
                            padding-top: 0.5rem;
                        }

                        &:last-child {
                            padding-bottom: 0.5rem;
                        }

                        a {
                            text-decoration: none;
                        }
                    }
                }

                &:hover {
                    background: lighten($primary,5%);

                    ul.layer-2 {
                        display: block !important;
                        pointer-events: visible;
                        z-index: 2;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
