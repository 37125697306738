// Cookies
.cookies-section {
    background-color: $gray-200;
    position: relative;
    z-index: 99;

    .cookie-graphic {
        align-items: center;
        display: flex;
        width: 4rem;
    }

    .cookie-message {
        align-items: center;
        display: flex;
        flex: 1;
    }

    .cookie-buttons {
        white-space: nowrap;
        align-items: center;
        display: flex;

        @include media-breakpoint-between(sm,lg) {
            padding-left: 4rem;
        }
    }

    .btn-danger {
        //font-size: 15px;
        text-transform: lowercase;
        text-decoration: underline;
        color: #3d3e3e;
        border: 0;
        background: none;

        &:hover {
            text-decoration: none;
        }
    }

    .btn {
        &::after {
            display: none;
        }
    }
}
