@charset "utf-8";

@import '_variables.scss';

// Third party
@import 'bootstrap/scss/bootstrap';
@import '../../fonts/base/styles';


@mixin img-half(
    $padding: 1rem
) {
    width: min(calc(50vw - #{$padding}), calc(960px - #{$padding}));
}

// Site
@import 'fonts';
@import 'global';
@import 'elements/alert';
@import 'elements/breadcrumb';
@import 'elements/list-group';
@import 'elements/slick-slider';
@import 'elements/form-feedback';
@import 'components/contact-box';
@import 'components/dev-grid';
@import 'components/cookies-card';
@import 'components/error-page';
@import 'components/cookies-section';
@import 'components/header-section';
@import 'components/footer-section';
@import 'components/slider-section';
@import 'components/slider-section';
@import 'components/searchbar-section';
@import 'components/input-group-inside';
@import 'components/mobile-menu';
@import 'components/google-maps';
@import 'components/highlight-section';
@import 'components/slider-example';
@import 'components/slider-media';

// General styling
body {
    font-family: $content-font-family;
    font-size: 19px;
    line-height: 29px;
    color: $font;

    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 26px;
    }
}

.site-wrapper {
    box-shadow: 0 0 48px rgba(0,0,0,0.1);
    background-color: #fff;
    position: relative;
    margin: auto;
    max-width: 1920px;
    overflow: hidden;
    width: 100%;
}

.container-xl {
    max-width: 1400px;
}

// Headings
h1, h2, h3, h4, h5, h6 {
    font-family: $headings-font-family;
    font-weight: 700;
    color: inherit;
}

h1 {
    font-size: 60px;
    line-height: 60px;
    color: $primary;

    @media all and (max-width: 1400px) {
        font-size: 50px;
        line-height: 50px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 40px;
        line-height: 40px;
    }
}

h2, .h2 {
    margin-bottom: $spacer*1.5;
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
    color: $primary;

    @media all and (max-width: 1400px) {
        font-size: 50px;
        line-height: 50px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 40px;
        line-height: 40px;
    }
}

.subtitle {
    margin-bottom: $spacer;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: $secondary;

    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 26px;
    }
}

.subtitle-red {
    font-family: $handwritten-font;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: $primary;

    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 30px;
    }
}

.img-lg-vw50 {
    border-radius: 10px;
    overflow: hidden;

    @media all and (min-width: 992px) {
        @include img-half();
        border-radius: 10px 0 0 10px;
        max-width: unset;
        overflow: hidden;
    }
}


// Buttons
.btn {
    font-weight: 700;
    border-radius: 0 5px 5px 5px;
}

.link-arrow {
    position: relative;
    transition: opacity .3s ease-in;

    &::after {
        content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 188.75 93.92'><path id='Path_354' data-name='Path 354' d='M192.75,50.88l-1-.89L149.33,4l-9.63,8.89L168.79,44.4H4V57.52H168.79L139.7,89l9.63,8.89,42.44-46,1-.89-.07-.08Z' transform='translate(-4 -4)' fill='%23272727'/></svg>");
        vertical-align: middle;
        display: inline-block;
        margin-left: $spacer/2;
        width: 28px;
    }

    &:hover {
        color: $primary;

        &::after {
            content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 188.75 93.92'><path id='Path_354' data-name='Path 354' d='M192.75,50.88l-1-.89L149.33,4l-9.63,8.89L168.79,44.4H4V57.52H168.79L139.7,89l9.63,8.89,42.44-46,1-.89-.07-.08Z' transform='translate(-4 -4)' fill='%23CF0025'/></svg>");
            margin-left: $spacer;
            transition: all .2s ease-in-out;
        }
    }
}

.link-arrow-red {
    position: relative;
    color: $primary;
    transition: opacity .3s ease-in;

    &::after {
        content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 188.75 93.92'><path id='Path_354' data-name='Path 354' d='M192.75,50.88l-1-.89L149.33,4l-9.63,8.89L168.79,44.4H4V57.52H168.79L139.7,89l9.63,8.89,42.44-46,1-.89-.07-.08Z' transform='translate(-4 -4)' fill='%23CF0025'/></svg>");
        vertical-align: middle;
        display: inline-block;
        margin-left: $spacer/2;
        width: 28px;
    }

    &:hover {
        color: $secondary;

        &::after {
            content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 188.75 93.92'><path id='Path_354' data-name='Path 354' d='M192.75,50.88l-1-.89L149.33,4l-9.63,8.89L168.79,44.4H4V57.52H168.79L139.7,89l9.63,8.89,42.44-46,1-.89-.07-.08Z' transform='translate(-4 -4)' fill='%2320284D'/></svg>");
            margin-left: $spacer;
            transition: all .2s ease-in-out;
        }
    }
}


// Services
.service-item {
    display: block;
    text-decoration: none;
    padding: $spacer*3 $spacer $spacer*2;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    border-radius: 0 10px 10px 10px;
    background-color: $tertiary;

    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 26px;
    }

    img {
        margin-bottom: $spacer*2;
    }
}


// Team
.team-item {
    margin-bottom: 150px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);

    img {
        border-radius: 50%;
        width: 165px;
        margin-top: -115px;
    }

    .name {
        margin-top: 0.625rem;
        font-weight: 700;
        color: $secondary;
    }

    .function {
        margin-bottom: 0.625rem;
        color: $secondary;
    }

    .phone {
        margin: 0.625rem 0 1rem;

        a {
            text-decoration: none;
            color: $secondary;
            font-weight: 700;
        }
    }
}


// Listing
.home-listings, .extra-listings {
    position: relative;

    h2 {
        color: $secondary;
    }
}

.slider-listing {
    width: 1800px;

    @media all and (max-width: 660px) {
        width: 100%;

        .slide-item {
            margin: 0 !important;
        }
    }
}

.nav-arrows {
    .listing-arrow-left {
        margin-right: $spacer/1.5;
        cursor: pointer;

        svg {
            width: 30px;
            transform: rotate(180deg);

            path {
                fill: $primary;
            }
        }
    }

    .listing-arrow-right {
        cursor: pointer;

        svg {
            width: 30px;

            path {
                fill: $primary;
            }
        }
    }
}

.listing-item {
    display: block;
    text-decoration: none;
    background-color: $white;
    border-radius: 0 10px 10px 10px;
    border: 2px solid $tertiary;

    img {
        border-radius: 0 10px 0 0;
    }

    .status {
        position: absolute;
        bottom: 0; left: $spacer;
        padding: 2px $spacer;
        font-family: $headings-font-family;
        font-size: 18px;
        font-weight: 700;
        color: $white;
        background-color: $primary;
        border-radius: 5px 5px 0 0;
    }

    .listing-details {
        padding: $spacer;

        .title {
            margin-bottom: $spacer;
            font-size: 25px;
            font-weight: 700;
            line-height: 30px;
            color: $secondary;
        }

        .price {
            float: left;
            color: $primary;

            @media all and (max-width: 470px) {
                float: none;
            }
        }

        .details {
            float: right;

            @media all and (max-width: 470px) {
                float: none;
                margin-top: $spacer;
            }

            svg {
                height: 18px;
            }

            .plotsize {
                svg {
                    height: 20px;
                }
            }

            .rooms {
                svg {
                    height: 13px;
                }
            }
        }

    }
}

// Filters
.overview-listings {
    .filter-mobile {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        .filter-mobile {
            display: block;
            margin-bottom: 20px;

            &::after {
                display: none !important;
            }
        }

        .options-mobile {
            display: none;
        }
    }
}

.filter-item {
    font-weight: 700;
    color: $secondary;

    @media all and (max-width: 1250px) {
        flex: 0 0 33%;
        max-width: 33%;
        margin-bottom: 20px;
    }

    @media all and (max-width: 700px) {
        flex: 0 0 50%;
        max-width: 50%;
    }

    @media all and (max-width: 470px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.filter-buttons {
    button {
        border: 0;
        background: none;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    @media all and (max-width: 1250px) {
        flex: 0 0 66%;
        max-width: 66%;
        margin-bottom: 1.25rem;
    }

    @media all and (max-width: 700px) {
        margin-top: 0;
    }

    @media all and (max-width: 470px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}


// Listing detail
.listing-images {
    max-width: 1795px;
    margin-right: 0;
    padding-right: 0;

    img {
        border-radius: 0 10px 10px 10px;
    }
}

.listing-detail {
    h1 {
        color: $secondary;
    }

    @include media-breakpoint-down(md) {
        .pr-5 {
            padding-right: 0.625rem !important;
        }
    }

    .status {
        display: inline-block;
        padding: 2px $spacer;
        font-family: $headings-font-family;
        font-size: 18px;
        font-weight: 700;
        color: $white;
        background-color: $primary;
        border-radius: 5px;
    }

    .price {
        font-size: 35px;
        line-height: 40px;
        text-align: right;
        color: $primary;
        float: right;

        @include media-breakpoint-down(sm) {
            float: none;
            text-align: left;
            margin-bottom: 0.625rem;
        }
    }

    h1 {
        font-family: $content-font-family;
        font-size: 50px;

        @include media-breakpoint-down(sm) {
            font-size: 40px;
            line-height: 40px;
        }
    }

    .city {
        margin: $spacer 0;
        font-size: 35px;
    }

    .breadcrumb {
        float: left;
        font-size: 0.8rem;

        @include media-breakpoint-down(sm) {
            float: none;
        }

        li {
            padding-left: 0.125rem !important;

            &::before {
                padding-right: 0.125rem !important;
            }
        }
    }

    .details {
        float: right;
        margin-top: 0;

        @include media-breakpoint-down(sm) {
            float: none;
        }

        li {
            &:not(:last-child) {
                margin-right: $spacer;
            }

            @media all and (max-width: 400px) {
                width: 100%;
                margin-top: $spacer/2 !important;
            }
        }

        svg {
            width: 30px;
            margin-right: $spacer/2;
            vertical-align: middle;
            display: inline-block;
        }

        .plotsize svg {
            width: 20px;
        }
    }

    .nav-tabs {
        font-weight: 700;
        color: $secondary;
        border: 0;

        &:not(.nav-dropdown) {
            .nav-item {
                padding: 1.25rem 3rem 2rem 0;
            }

            .nav-link {
                padding: 0 0 0.125rem;
                border: 0;

                position: relative;

                &::before {
                    border-bottom: 0.125rem solid $primary;
                    transition: 200ms all ease-in;
                    bottom: -0.1875rem; left: 0;
                    position: absolute;
                    content: '';
                    width: 0;
                }

                &:hover {
                    &::before {
                        width: 100%;
                    }
                }

                &.active {
                    color: $secondary;
                    border: 0;

                    &::before {
                        width: 100%;
                    }
                }

                &:hover {
                    border: 0;
                }
            }
        }

        &.nav-dropdown {
            border: 1px solid #ddd; border-top: 0;
            box-shadow: 0 3px 6px rgba(0,0,0,0.1);
            flex-direction: column;
            background-color: #fff;
            position: absolute;
            left: 0; right: 0;
            z-index: 100;
            display: none;
            top: 100%;

            &.active {
                display: block;
            }

            .nav-link {
                border: 0;
            }
        }
    }

    .nav-tabs-wrapper {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        position: relative;
    }

    .nav-tabs-trigger {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            border: 1px solid #ddd;
            position: relative;

            &::after {
                transform: translateY(-50%);
                background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' class='svg-icon_arrow-down' preserveAspectRatio='xMinYMin' viewBox='-5 -4.5 24 24'><path style='fill: currentColor;' d='M8 11.243l3.95-3.95a1 1 0 111.414 1.414l-5.657 5.657a.997.997 0 01-1.414 0L.636 8.707A1 1 0 112.05 7.293L6 11.243V1.657a1 1 0 112 0v9.586z'/></svg>");
                background-position: center;
                background-size: contain;
                pointer-events: none;
                position: absolute;
                width: 1.4rem;
                content: '';
                height: 1.4rem;
                right: 0.625rem;
                top: 50%;
            }
        }
    }

    .more-info {
        border-left: 1px solid $secondary;

        .title {
            margin-bottom: 0.625rem;
            font-weight: 700;
            color: $secondary;
        }
    }
}

.tab-content {
    #google_maps {
        height: 500px !important;
    }
}


// Instagram feed
.instafeed {
    position: relative;

    .eapps-instagram-feed-title-container, a.eapps-link {
        display: none !important;
    }

    .row {
        position: relative;
        z-index: 5;
    }

    .btn {
        margin-right: 20px;

        @include media-breakpoint-down(md) {
            margin-right: 0;
        }
    }
}


form {
    .form-control {
        border-radius: 0;
        border-color: #ddd;
    }
}


// C2A section
.c2a-section {
    position: relative;
    border-radius: 0 10px 10px 10px;
    background-color: #e7e8ec;

    .label {
        position: absolute;
        top: 0; left: 50px;
        padding: 2px $spacer;
        font-family: $headings-font-family;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        color: $white;
        background-color: $primary;
        border-radius: 0 0 5px 5px;
    }

    img {
        border-radius: 0 10px 10px 10px;

        @include media-breakpoint-down(sm) {
            margin-top: $spacer*2;
        }
    }

    h2 {
        color: $secondary;

        @include media-breakpoint-down(sm) {
            font-size: 40px;
            line-height: 40px;
        }
    }

    ul {
        float: left;
    }

    li {
        color: $primary;
    }

    svg {
        float: right;
        width: 55px;
    }
}


// Quotes
.quote-section {
    position: relative;

    img {
        position: absolute;
        bottom: 0;
        left: 80px;

        @media all and (max-width: 1585px) {
            left: 0;
        }

        @media all and (max-width: 1375px) {
            max-width: 20%;
        }

        @include media-breakpoint-down(md) {
            position: relative;
            bottom: auto;
            left: auto;
            max-width: 100%;
            margin: auto;
            display: block;
        }
    }

    .quote {
        font-family: $headings-font-family;
        font-size: 55px;
        font-weight: 700;
        line-height: 55px;

        @include media-breakpoint-down(md) {
            font-size: 35px;
            line-height: 35px;
        }

        .author {
            margin-top: $spacer*1.5;
            font-family: $handwritten-font;
            font-size: 30px;
            font-weight: 700;
            line-height: 35px;
            color: $primary;

            @include media-breakpoint-down(md) {
                font-size: 22px;
                line-height: 30px;
            }
        }
    }

    img + .row {
        .quote {
            max-width: 700px;
            margin: auto;
        }
    }

    &.service + .footer-section {
        margin-top: 0;
    }
}


// Quote service overview
.service-quote {
    @include media-breakpoint-down(md) {
       margin: 0 !important;
    }
}


// Images services
.service-right {
    img {
        max-width: unset;
        @include img-half();;
        border-radius: 10px 0 0 10px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: auto;
            border-radius: 0 10px 10px 10px;
            margin-top: 30px;
        }
    }

    .pl-5 {
        @include media-breakpoint-down(md) {
            padding-left: 15px !important;
        }
    }
}

.service-left {
    img {
        max-width: unset;
        @include img-half();;
        border-radius: 0 10px 10px 0;
        float: right;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: auto;
            border-radius: 0 10px 10px 10px;
            margin-top: 30px;
            float: none;
        }
    }

    .pr-5 {
        @include media-breakpoint-down(md) {
            padding-right: 15px !important;
        }
    }
}


// Home blocks
.home-block2 {
    img {
        float: right;
        max-width: unset;
        @include img-half();;
        border-radius: 0 10px 10px 0;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: auto;
            border-radius: 0 10px 10px 10px;
            margin-top: 30px;
            float: none;
        }
    }

    .pr-5 {
        @include media-breakpoint-down(md) {
            padding-right: 15px !important;
        }
    }
}

// About
.about-home {
    ul {
        float: left;
    }

    .social-home {
        float: left;
        margin: $spacer*1.8 0 0 $spacer*4;
    }

    img {
        max-width: unset;
        @include img-half();
        border-radius: 10px 0 0 10px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: auto;
            border-radius: 0 10px 10px 10px;
            margin-top: 30px;
        }
    }
}

.about-detail {
    img {
        max-width: unset;
        @include img-half();;
        border-radius: 10px 0 0 10px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: auto;
            border-radius: 0 10px 10px 10px;
            margin-top: 30px;
        }
    }

    .pl-5 {
        @include media-breakpoint-down(md) {
            padding-left: 15px !important;
        }
    }
}

.about-detail-left {
    img {
        max-width: unset;
        @include img-half();
        border-radius: 0 10px 10px 0;
        float: right;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: auto;
            border-radius: 0 10px 10px 10px;
            margin-top: 30px;
            float: none;
        }
    }

    .pr-5 {
        @include media-breakpoint-down(md) {
            padding-right: 15px !important;
        }
    }
}


// Contact
.contact {
    img {
        max-width: unset;
        @include img-half();;
        border-radius: 10px 0 0 10px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: auto;
            border-radius: 0 10px 10px 10px;
            margin-top: 30px;
        }
    }

    .pl-5 {
        @include media-breakpoint-down(md) {
            padding-left: 15px !important;
        }
    }
}

ul.contact-details {
    float: left;
}

.social-contact {
    float: left;
    margin: $spacer*1.8 0 0 $spacer*4;
}


.body_listing {
    .bg-tertiary {
        margin-top: 80px;
    }

   .bg-tertiary + .footer-section {
       margin-top: 0;
   }
}

.contactform {
    padding: 85px 20px 0 90px;

    @media all and (max-width: 1715px) {
        padding-left: 75px;
    }

    @include media-breakpoint-down(md) {
        padding: $spacer;
    }
}
