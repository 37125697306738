// Slider
.slider-section {
    position: relative;
    background: $white;
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }

    .container-xl {
        max-width: 1795px;
        margin-right: 0;
        padding-right: 0;
    }

    .slide-video {
        position: relative;
        z-index: 2;
    }

    .slide-image {
        img {
            border-radius: 10px 0 0 10px;
        }

        @include media-breakpoint-down(md) {
            margin-right: -25%;
            max-width: 150%;
        }

        @include media-breakpoint-down(sm) {
            margin-right: -50%;
            max-width: 200%;
        }

        @include media-breakpoint-down(xs) {
            margin-right: -150%;
            max-width: 400%;
        }
    }

    // Navigation color
    [class^="slider"] .slick-next::before,
    [class^="slider"] .slick-prev::before {
        text-shadow: 0 0 $spacer rgba(0,0,0,0.5);
        color: $white;
    }

    // Dots color
    .slick-dots li button::before,
    .slick-dots li.slick-active button::before {
        text-shadow: 0 0 $spacer rgba(0,0,0,0.5);
        color: $white;
    }


        .slide-content {
            position: absolute;
            bottom: 100px;
            left: 115px;

            @media all and (max-width: 1430px) {
                left: 0;
            }

            @media all and (max-width: 1120px) {
                bottom: 20px;
            }

            .container,
            .container-xl,
            .container-fluid {

                .slide-caption {
                    user-select: text;
                    padding: $spacer;
                    float: left;
                    color: $white;
                    max-width: 840px;
                    position: relative;
                    z-index: 3;

                    h1 {
                        margin-bottom: $spacer*3;
                        font-size: 80px;
                        line-height: 70px;
                        color: $white;

                        @media all and (max-width: 1430px) {
                            font-size: 60px;
                            line-height: 60px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 35px;
                            line-height: 35px;
                        }
                    }

                    .btn-outline-white {
                        font-family: $handwritten-font;
                        font-size: 30px;
                        font-weight: 700 !important;
                        color: $white;
                        border: 2px solid $white;

                        @include media-breakpoint-down(sm) {
                            font-size: 20px;
                            line-height: 25px;
                        }

                        &:hover {
                            color: $primary;
                            background-color:  $white;
                        }
                    }
                }
            }
        }
}
