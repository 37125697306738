// Highlight
.highlight-section {
    .container-fluid {
        padding: 0;
    }

    img {
        width: 100%;
    }
}
