// Footer
.footer-section {
    margin-top: 80px;
    font-size: 15px;
    line-height: 24px;
    color: $white;
    background-color: $secondary;

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    .address {
        li:first-child {
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
        }

        @include media-breakpoint-down(sm) {
            text-align: center;
            float: none;
        }
    }

    .footer-menu {
        @extend .list-inline;
        float: left;

        @include media-breakpoint-down(sm) {
            text-align: center;
            float: none;
        }

        a {
            @extend .link-inverted;
        }

        li {
            &::before {
                content: '|';
                padding-right: 10px;
            }

            @include media-breakpoint-down(sm) {
                &:first-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .copyright {
        float: left;
        margin-right: $spacer;

        @include media-breakpoint-down(sm) {
            text-align: center;
            float: none;
            margin-right: 0;
        }
    }

    .socialmedia {
        margin-top: $spacer*2;
        font-size: 25px;

        a {
            .icon {
                transition: 200ms all ease-in;
                display: inline-block;
                transform-origin: center;
                transform: scale(1);
            }

            &:hover {
                .icon {
                    transform: scale(1.1);
                    color: $primary;
                }
            }
        }
    }

    .quality-labels {
        margin-bottom: $spacer*5;

        @include media-breakpoint-down(md) {
            padding-top: $spacer*2;
            margin-bottom: $spacer;
        }

        li {
            margin: 0 40px 0 0;
        }
    }
}
